import { REMOVE_GOOGLE_TESTIMONIAL } from 'lib/@getethos/experiments/feature_constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

import { TestimonialProps } from '@/components/TestimonialSlider/TestimonialSlider'

const TESTIMONIALS_WITHOUT_GOOGLE: TestimonialProps[] = [
  {
    platform: 'trustpilot',
    body: "I can't believe the pricing and coverage, and how simple this process was with Ethos Life!!!",
    name: 'Ryan S.',
    date: 'January 2024',
  },
  {
    platform: 'trustpilot',
    body: 'The speed and simplicity of the whole process is amazing!',
    name: 'Jose A.',
    date: 'March 2024',
  },
  {
    platform: 'trustpilot',
    body: 'The extras such as free will, security protection, and estate planning... is worth every penny.',
    name: 'Jacqueline G.',
    date: 'November 2023',
  },
  {
    platform: 'trustpilot',
    body: 'The ease at which I was able to protect my family is incredible.',
    name: 'Emily W.',
    date: 'February 2023',
  },
  {
    platform: 'trustpilot',
    body: 'Best life insurance ever... I would give them 10 stars if I could.',
    name: 'Maple E.',
    date: 'November 2023',
  },
]

export const useRemoveGoogleTestimonial = () => {
  const { isLoading, variation, isEnabled } = useDecisionForFlag({
    name: REMOVE_GOOGLE_TESTIMONIAL.name,
    fallbackVariation: REMOVE_GOOGLE_TESTIMONIAL.variations.off,
  })

  return {
    testimonialsWithoutGoogle: TESTIMONIALS_WITHOUT_GOOGLE,
    isLoading,
    isEnabled,
    isOffVariation: variation === REMOVE_GOOGLE_TESTIMONIAL.variations.off,
    isOnVariation: variation === REMOVE_GOOGLE_TESTIMONIAL.variations.on,
  }
}
